import { FunctionComponent, useEffect } from 'react';
import RegisterModal from '@pages/auth/components/register-modal';

import Footer from '@ui/layouts/footer';
import NavBar from '@ui/layouts/navbar';
import { PageSection as UIPageSection } from '@ui/layouts/navbar/interface';

import { LayoutProps } from './interface';
import { trackingLoginRegister } from './tracking';
// import { canUseDOM } from '@utils';
import 'rc-dialog/assets/index.css';
// import a plugin
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

const Layout: FunctionComponent<LayoutProps> = ({ children, prefix, isTransparent, section }) => {
  useEffect(() => {
    trackingLoginRegister();
    registerServiceWorker().then((reg) => {
      if (!reg) return;
      reg.update().then(() => {
        console.log('SW! Updated');
      });
    });
  }, []);

  const registerServiceWorker = () => {
    if (!window.navigator.serviceWorker) return Promise.resolve(undefined);
    return window.navigator.serviceWorker
      .register('/sw.js')
      .then((reg) => {
        console.log('SW registered!');
        return reg;
      })
      .catch((err) => console.log(`SW can't install: `, err));
  };

  return (
    <div>
      <NavBar prefix={prefix} isTransparent={isTransparent} sticky section={section as UIPageSection} />
      <RegisterModal prefix={prefix} />
      {children}
      <Footer prefix={prefix} />
    </div>
  );
};

export default Layout;

import { InputHTMLAttributes, DetailedHTMLProps, FunctionComponent, Fragment } from 'react';
import './style.scss';
export interface FormikInputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  children?: React.ReactNode;
}

const FormikInput: FunctionComponent<FormikInputProps> = (props) => {
  const { type, placeholder, className, autoComplete, onChange, name, onKeyPress, onBlur, value, disabled, id } = props;
  return (
    <Fragment>
      <input
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        autoComplete={autoComplete}
        className={`dh-input-formik ${className ? className : ''}`}
        onChange={(e) => {
          if (!onChange) return;
          return onChange(e);
        }}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
      />
      {props.children}
    </Fragment>
  );
};

export default FormikInput;

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FunctionComponent } from 'react';

interface ComfirmModalProps {
  toggleComfirmModal: () => void;
  openConfirmModal: boolean;
  email: string;
  handleComfirmSubmit: () => void;
}

export const ComfirmModal: FunctionComponent<ComfirmModalProps> = ({
  toggleComfirmModal,
  openConfirmModal,
  email,
  handleComfirmSubmit
}) => {
  return (
    <Modal show={openConfirmModal} onHide={toggleComfirmModal} centered className="confirm-modal">
      <Modal.Body className="confirm-modal__body">
        <i id="account-modify-email-close" className="icon-close" onClick={toggleComfirmModal} />
        <p>คุณต้องการเปลี่ยนแปลงอีเมล?</p>
        อีเมลใหม่ของคุณคือ {email}
        <div className="d-flex flex-column pt-3">
          <Button id="account-modify-email-submit" block onClick={handleComfirmSubmit}>
            ยืนยัน
          </Button>
          <Button id="account-modify-email-cancel" block variant="light" onClick={toggleComfirmModal}>
            ยกเลิก
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

import { sendToDataLayer, PageSection, getDefaultEventCategory } from '@utils';
import { SocialButton } from '@pages/auth/components/social-networks/tracking';
export enum RegisterStatus {
  Success = 'register_success',
  Fail = 'register_fail'
}

export const trackSocialEmailRegister = () => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.Register),
    event_action: `register`,
    event_label: `by_email`
  });
};

export const trackSocialRegister = (which: SocialButton) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.Register),
    event_action: `login`,
    event_label: `${which}`
  });
};

export const trackOnClickElement = (which: string) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.Register),
    event_label: `${which}`
  });
};

export const trackOnClickNewsletter = (state: boolean) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.Register),
    event_label: `${state ? 'checked' : 'unchecked'}`
  });
};

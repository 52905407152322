import { sendToDataLayer, PageSection, getDefaultEventCategory } from '@utils';
import { FormikErrors } from 'formik';
import { LoginFormValues } from '@pages/auth/components/login-form-field';
import { SocialButton } from '@pages/auth/components/social-networks/tracking';

export enum LoginStatus {
  Success = 'login_success',
  Fail = 'login_fail'
}

const getLoginSubmitLabel = (status: LoginStatus, error?: FormikErrors<LoginFormValues>, errorText?: string) => {
  if (status === LoginStatus.Success) return 'login_success';
  if (!error) return `${error}`;
  if (!error.email || !error.password) return `by_email_${errorText}`;
  return `by_email_empty_email_empty_password`;
};

export const trackLoginSubmit = (status: LoginStatus, error?: FormikErrors<LoginFormValues>, errorText?: string) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.Login),
    event_action: `${status}`,
    event_label: getLoginSubmitLabel(status, error, errorText)
  });
};

export const trackSocialLogin = (which: SocialButton) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.Login),
    event_action: `login`,
    event_label: `${which}`
  });
};

export const trackOnClickElement = (which: string) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.Login),
    event_label: `${which}`
  });
};

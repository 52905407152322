import React, { useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { CountDown } from '../countdown';
import { calculateDateTimeLeft } from '../utils/index';
import './style.scss';
interface CountdownBannerProps {
  prefix: string;
  coupon: string;
  expireTimestamp: number;
  visible: boolean;
  toggleVisible: () => void;
}

export const CountdownBanner = React.forwardRef<HTMLDivElement, CountdownBannerProps>((props, ref) => {
  useEffect(() => {
    const differenceDateTime = calculateDateTimeLeft(props.expireTimestamp);

    if (
      differenceDateTime.days() <= 0 &&
      differenceDateTime.hours() <= 0 &&
      differenceDateTime.minutes() <= 0 &&
      differenceDateTime.seconds() <= 0
    ) {
      props.toggleVisible();
    }
  }, []);

  const copyCoupon = () => {
    navigator.clipboard.writeText(props.coupon);
  };

  return (
    <Alert
      show={props.visible}
      onClose={props.toggleVisible}
      className="promotion-banner promotion-banner--countdown m-0 rounded-0"
      ref={ref}
    >
      <div>
        โค้ด
        <p className="coupon-code" onClick={copyCoupon}>
          {props.coupon}
        </p>
        หมดอายุภายใน
        <CountDown
          prefix={props.prefix}
          expireTimestamp={props.expireTimestamp}
          onCountDown={(timeLeft) => {
            if (timeLeft.days <= 0 && timeLeft.hours <= 0 && timeLeft.minutes <= 0 && timeLeft.seconds <= 0) {
              props.toggleVisible();
            }
          }}
        />
      </div>
    </Alert>
  );
});

CountdownBanner.displayName = 'CountdownBanner';

import React, { useState, useEffect, createRef, useContext } from 'react';
import { canUseDOM, readCookie } from '../utils';
import { ContextType } from '../context';
import { PromotionWelcome } from './promotion-welcome';
import { CountdownBanner } from './countdown-banner';
import { OPTIMIZE_CAMPAIGN_ID } from '../../config';
import './style.scss';
interface PromotionBannerProps {
  prefix: string;
  setNavbarPlaceHolderHeight: (number: number) => void;
}

enum PromotionStep {
  Welcome,
  Included
}
type Coupon = {
  id: number;
  coupon: string;
  start_date: number;
  expiration_date: number;
  is_active: boolean;
  amount: number;
  redeemed_at: number;
  created_at: number;
  updated_at: number;
};

const getUserCoupons = async (host: string, token: string | null) => {
  if (token == null) return [] as Coupon[];
  const axios = await (await import('axios')).default;
  return axios
    .get<Coupon[]>(`${host}/api/v1/campaigns/${OPTIMIZE_CAMPAIGN_ID}/coupons`, {
      headers: {
        Authorization: token
      }
    })
    .then(({ data }) => data)
    .catch(() => [] as Coupon[]);
};

export const PromotionBanner: React.FunctionComponent<PromotionBannerProps> = ({
  prefix,
  setNavbarPlaceHolderHeight
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [promotionStep, setPromotionStep] = useState<PromotionStep | undefined>(undefined);

  const [coupons, setCoupons] = useState<Coupon[]>([]);

  const ctx = useContext(ContextType);
  const ref = createRef<HTMLDivElement>();
  const defaultHeight = 562;

  const getElemRef = () => {
    if (!canUseDOM()) return [null, null];
    const bannerSearchRef = document.getElementById('banner-box');
    const contentSearchRef = document.getElementById('content-box');
    return [bannerSearchRef, contentSearchRef];
  };
  const [bannerSearchRef, contentSearchRef] = getElemRef();

  const setUserVariant = (variant: string | null) => {
    if (variant === '0' || variant === null) return;
    if (coupons.length > 0) return;
    setPromotionStep(PromotionStep.Welcome);
    if (ctx.accessToken) {
      getUserCoupons(ctx.legacyHost, ctx.accessToken)
        .then((data) => {
          if (data.length === 0) return;
          setCoupons(data);
          setVisible(true);
          setPromotionStep(PromotionStep.Included);
        })
        .catch((e) => console.log(e));
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const variant = readCookie('VARIANT_P');
      setVisible(true);
      setUserVariant(variant);
    }, 2500);
  }, []);

  useEffect(() => {
    if (!bannerSearchRef) return;
    if (!contentSearchRef) return;

    bannerSearchRef.style.height = `${defaultHeight + (ref.current ? ref.current.clientHeight : 0)}px`;
    if (ctx.isMobile) {
      contentSearchRef.style.paddingTop = `${52 + (ref.current ? ref.current.clientHeight : 0)}px`;
      contentSearchRef.style.paddingBottom = `0px`;
      return;
    }
    contentSearchRef.style.paddingTop = `0px`;
    contentSearchRef.style.paddingBottom = `16px`;
  }, [ctx.isMobile, bannerSearchRef, contentSearchRef, visible]);

  const toggleVisible = () => {
    setVisible(false);
    if (!bannerSearchRef) return;
    if (!contentSearchRef) return;

    if (ctx.isMobile) contentSearchRef.style.paddingTop = `52px`;
    bannerSearchRef.style.height = `${defaultHeight}px`;
  };

  const countdownBanner = () => {
    if (coupons.length == 0) {
      return <div />;
    }

    const coupon = coupons[0];
    return (
      <CountdownBanner
        prefix={prefix}
        toggleVisible={toggleVisible}
        visible={visible}
        coupon={coupon.coupon}
        expireTimestamp={coupon.expiration_date}
      />
    );
  };

  switch (promotionStep) {
    case PromotionStep.Welcome:
      return (
        <PromotionWelcome
          prefix={prefix}
          toggleVisible={toggleVisible}
          visible={visible}
          setParentHeight={(height) => setNavbarPlaceHolderHeight(height)}
        />
      );
    case PromotionStep.Included:
      return countdownBanner();
    default:
      return <div />;
  }
};

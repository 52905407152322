import { readCookie, deleteCookie, sendToDataLayer, getDefaultEventCategory, PageSection } from '@utils';
import { SocialAction } from '@pages/auth/components/social-networks/interface';
import { LoginStatus } from '@pages/auth/section/login/tracking';
import { RegisterStatus } from '@pages/auth/section/register/tracking';

const deletetrackingLoginRegister = () => {
  deleteCookie('_socialEventIntention');
  deleteCookie('_socialChannel');
  deleteCookie('_socialEventActual');
};

const getTrackingChannel = (socialChannel: string) => {
  switch (socialChannel) {
    case 'google':
      return 'googlemail';
    case 'facebook':
      return 'facebook';
    case 'email':
      return 'email';
    default:
      return 'undefined';
  }
};

export const trackingLoginRegister = () => {
  const socialEventIntension = readCookie('_socialEventIntention');
  const socialEventActual = readCookie('_socialEventActual');
  const socialChannel = readCookie('_socialChannel');
  const consumerID = readCookie('_consumerID');
  if (!socialEventIntension || !socialChannel || !consumerID || !socialEventActual)
    return deletetrackingLoginRegister();
  const eventCategory = socialEventIntension === SocialAction.Login ? PageSection.Login : PageSection.Register;
  const customDimension = {
    userid: `${consumerID}`,
    login_channel: `${getTrackingChannel(socialChannel)}`
    // user_rating: '{rating}' FYI: Not implement yet (2020-10-26).
  };

  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(eventCategory),
    event_action: socialEventActual === SocialAction.Login ? LoginStatus.Success : RegisterStatus.Success,
    event_label: socialEventActual === SocialAction.Login ? LoginStatus.Success : RegisterStatus.Success,
    ...customDimension
  });
  deletetrackingLoginRegister();
};

export const trackVirtualpageView = (pageTitle: string, pagePath: string, isMobile: boolean) => {
  if (!window.dataLayer) return;
  const device = isMobile ? 'mobile' : 'desktop';
  const consumerID = readCookie('_consumerID');

  window.dataLayer.push({
    event: 'pageview_metadata',
    pageTitle: pageTitle,
    pagePath: pagePath,
    customer_id: consumerID ? `${consumerID}` : `nologin_${device}`
  });
};

import { PureComponent } from 'react';
import { Formik } from 'formik';
import { AuthInfoBoxProps, AuthInfoBoxInitailFormValues } from './interface';
import { authUpdateInfo } from '@ui/components/context/auth';
import { readLocalStorage, setLocalStorage } from '@utils';
import FormikInput from '@shares/input';
import { string as yupString, object as yupObject } from 'yup';
import { ComfirmModal } from './confirm-modal';
import { AlertType } from '@shares/alert-box';
import { trackSubmitEdit } from './tracking';
import './style.scss';

const SCHEMA = () => {
  return yupObject().shape({
    first_name: yupString()
      .required('กรุณากรอกชื่อ')
      .matches(/^[a-zA-Zก-๛ \t\r\n\v\f]+$/, 'กรุณาใส่อักษรภาษาไทย หรือภาษาอังกฤษเท่านั้น'),
    last_name: yupString()
      .required('กรุณากรอกนามสกุล')
      .matches(/^[a-zA-Zก-๛ \t\r\n\v\f]+$/, 'กรุณาใส่อักษรภาษาไทย หรือภาษาอังกฤษเท่านั้น'),
    phone_number: yupString().matches(
      /^(0)[\d]{9}$/,
      'เบอร์โทรศัพท์มือถือจำเป็นต้องขึ้นต้นด้วย 0 และมีทั้งหมด 10 หลัก'
    ),
    email: yupString()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'อีเมลต้องมี @ และ .')
      .required('กรุณากรอกอีเมลของคุณ')
  });
};

class AuthInfoBox extends PureComponent<AuthInfoBoxProps, { openConfirmModal: boolean; isConfirmError: boolean }> {
  state = {
    openConfirmModal: false,
    isConfirmError: false
  };

  toggleComfirmModal = () => {
    this.setState({ openConfirmModal: !this.state.openConfirmModal });
  };

  updateMyInfo = (data: any, setSubmitting: (isSubmitting: boolean) => void) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    authUpdateInfo({ ...data })
      .then(() => {
        setLocalStorage('consumer_email', data.email);
        trackSubmitEdit();
        setTimeout(() => {
          setSubmitting(false);
          this.props.toggleAlertBox && this.props.toggleAlertBox(AlertType.Success);
          this.props.onSubmit && this.props.onSubmit();
        }, 1000);
      })
      .catch((e) => {
        setTimeout(() => {
          setSubmitting(false);
        }, 1000);
        console.log(e.data);
        if (e.status == 409) {
          this.setState({ isConfirmError: true });
          return (
            this.props.toggleAlertBox && this.props.toggleAlertBox(AlertType.Error, 'อีเมลนี้มีบัญชีสมาชิกอยู่แล้ว')
          );
        }
        this.props.toggleAlertBox && this.props.toggleAlertBox(AlertType.Error);
      });
  };

  mapValues = (values: AuthInfoBoxInitailFormValues) => {
    const data = {};
    Object.keys(values)
      .filter((key) => values[key] !== '')
      .forEach((k) => (data[k] = values[k]));

    if (data['email']) data['email'] = data['email'].toLowerCase();
    return data;
  };
  render() {
    const { initailFormValues, disabledEmailField, isError, className, infoFetching } = this.props;
    const isDataFetching = infoFetching && initailFormValues ? (infoFetching ? true : false) : false;
    return (
      <div className="auth-info-box">
        <div className="info-box">
          <Formik
            enableReinitialize
            initialValues={
              initailFormValues ? initailFormValues : { first_name: '', last_name: '', phone_number: '', email: '' }
            }
            onSubmit={(values, actions) => {
              const data = this.mapValues(values);
              if (!this.props.enableConfirmEmailModal || this.props.enableConfirmEmailModal === undefined)
                return this.updateMyInfo(data, actions.setSubmitting);

              const baseEmail = readLocalStorage('consumer_email');
              if (baseEmail !== data['email']) {
                return this.toggleComfirmModal();
              }
              this.updateMyInfo(data, actions.setSubmitting);
            }}
            validationSchema={SCHEMA}
          >
            {(props) => {
              return (
                <>
                  <ComfirmModal
                    toggleComfirmModal={() => {
                      this.toggleComfirmModal();
                      props.setSubmitting(false);
                    }}
                    openConfirmModal={this.state.openConfirmModal}
                    email={props.values.email}
                    handleComfirmSubmit={() => {
                      this.updateMyInfo(this.mapValues(props.values), props.setSubmitting);
                      this.toggleComfirmModal();
                    }}
                  />
                  <form onSubmit={props.handleSubmit} className={`info-form ${className ? className : ''}`}>
                    <div className="info-form__group">
                      <div className="info-form-input">
                        <p>ชื่อจริง (ตามบัตรประชาชน)*</p>
                        <FormikInput
                          id="account-name-input"
                          name="first_name"
                          placeholder={isDataFetching ? '' : 'กรอกชื่อ'}
                          type="text"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.first_name}
                          autoComplete="off"
                          className={`${props.errors.first_name ? 'error' : ''} ${
                            isDataFetching ? 'info-loading' : ''
                          }`}
                        >
                          {props.errors.first_name && <div className="feedback">{props.errors.first_name}</div>}
                        </FormikInput>
                      </div>
                      <div className="info-form-input">
                        <p>นามสกุล (ตามบัตรประชาชน)*</p>
                        <FormikInput
                          id="account-lastname-input"
                          name="last_name"
                          placeholder={isDataFetching ? '' : 'กรอกนามสกุล'}
                          type="text"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.last_name}
                          autoComplete="off"
                          className={`${props.errors.last_name ? 'error' : ''} ${isDataFetching ? 'info-loading' : ''}`}
                        >
                          {props.errors.last_name && <div className="feedback">{props.errors.last_name}</div>}
                        </FormikInput>
                      </div>
                    </div>
                    <div className="info-form__group">
                      <div className="info-form-input phone-input">
                        <p>เบอร์โทรศัพท์มือถือ</p>
                        <FormikInput
                          id="account-phone-input"
                          name="phone_number"
                          placeholder={isDataFetching ? '' : 'กรอกเบอร์โทรศัพท์มือถือ'}
                          type="tel"
                          onChange={(e) => {
                            e.target.value.length <= 10 && props.handleChange(e);
                          }}
                          onBlur={props.handleBlur}
                          value={props.values.phone_number}
                          autoComplete="off"
                          className={`${props.errors.phone_number ? 'error' : ''} ${
                            isDataFetching ? 'info-loading info-loading--phone' : ''
                          }`}
                          onKeyPress={(e) => {
                            const code = e.which ? e.which : e.keyCode;
                            if (code > 31 && (code < 48 || code > 57)) {
                              e.preventDefault();
                            }
                          }}
                        >
                          <div className="flag-box">
                            <img src="/assets/shares/th-icon.svg" />
                          </div>
                          {props.errors.phone_number && <div className="feedback">{props.errors.phone_number}</div>}
                        </FormikInput>
                      </div>
                      <div className="info-form-input">
                        <p>อีเมล</p>
                        <FormikInput
                          id="account-email-input"
                          name="email"
                          placeholder={isDataFetching ? '' : 'อีเมล'}
                          type="text"
                          onChange={(e) => {
                            if (this.props.enableConfirmEmailModal) this.setState({ isConfirmError: false });
                            props.handleChange(e);
                          }}
                          onBlur={props.handleBlur}
                          value={props.values.email}
                          autoComplete="off"
                          className={`${props.errors.email || this.state.isConfirmError ? 'error' : ''} ${
                            disabledEmailField ? 'disabled' : ''
                          } ${isDataFetching ? 'info-loading' : ''}`}
                          disabled={disabledEmailField ? true : false}
                          onKeyPress={(e) => {
                            const code = e.which ? e.which : e.keyCode;
                            if (code == 32) {
                              e.preventDefault();
                            }
                          }}
                        >
                          {props.errors.email && <div className="feedback">{props.errors.email}</div>}
                          {this.state.isConfirmError && <div className="feedback">อีเมลนี้มีบัญชีสมาชิกอยู่แล้ว</div>}
                        </FormikInput>
                      </div>
                    </div>
                    <p className="requirement-text">*จำเป็นต้องกรอก</p>
                    <button
                      type="submit"
                      className={`btn btn-block btn-primary ${props.isSubmitting ? 'submiting' : ''} ${
                        isError || Object.keys(props.errors).length > 0 ? 'error' : ''
                      }`}
                      id="account-submit"
                      disabled={props.isSubmitting || isError || Object.keys(props.errors).length > 0 ? true : false}
                    >
                      {props.isSubmitting ? (
                        <img src="/assets/shares/loading.svg" style={{ width: 38 }} />
                      ) : isError ? (
                        'โปรดลองใหม่อีกครั้ง'
                      ) : (
                        'บันทึก'
                      )}
                    </button>
                  </form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  }
}

export default AuthInfoBox;

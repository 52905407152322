import React, { PureComponent, createRef } from 'react';
import { ContextType } from '../../components/context';
import { NavBarState, NavBarProps } from './interface';
import { searchPath, readCookie, deleteCookie, setupDateTime } from '../../components/utils';
import { AuthenticationSectionMobile, AuthenticationSectionDesktop, DropdownAuthSection } from './auth';
import { DropdownAuthWelcome } from './auth-dropdown';
// import { CookieBox } from '../../components/cookie-box';
import { PromotionBanner } from '../../components/promotion-bar';
import { WEB_LEGACY_LINK } from '../../config';
import { dataLayer, pushDataLayer } from '@ui/utils/datalayer';
import LineIcon from '../../assets/icons/line-icon.svg';
import moment from 'moment';
export default class NavBar extends PureComponent<NavBarProps, NavBarState> {
  state = {
    isOpenMenu: false,
    isOpenDropdownAuth: false,
    isOpenWelcomeModal: false,
    welcomeModalID: 0,
    navbarPlaceholderHeight: 0
  };

  static contextType = ContextType;

  dropdownAuthRef = createRef<HTMLDivElement>();
  dropdownWelcomeRef = createRef<HTMLDivElement>();
  navbarAuthRef = createRef<HTMLButtonElement>();
  placeholderNavbarRef = createRef<HTMLDivElement>();

  componentDidMount() {
    document.addEventListener('scroll', this.handleOnScroll);
  }

  componentDidUpdate(prevProps: NavBarProps, prevState: NavBarState) {
    if (this.context.auth.isAuth) {
      const welcomeModal = readCookie('_welcomeModal');
      deleteCookie('_welcomeModal');
      if (!welcomeModal) return;
      clearTimeout(this.state.welcomeModalID);

      if (this.state.welcomeModalID === prevState.welcomeModalID) {
        const newWelcomeModalID = this.setTimeOutWelcomeModal();
        const num = Number(newWelcomeModalID);
        this.setState({ welcomeModalID: num });
      }

      if (this.state.isOpenDropdownAuth !== prevState.isOpenDropdownAuth) {
        clearTimeout(this.state.welcomeModalID);
        if (this.state.isOpenDropdownAuth) return this.setState({ isOpenWelcomeModal: false });
      }
    }
  }

  setTimeOutWelcomeModal = () => {
    return setTimeout(
      () =>
        this.setState({ isOpenWelcomeModal: true }, () => {
          document.addEventListener('click', this.handleOutsideClick, false);
          setTimeout(() => this.toggleWelcomeModal(), 4000);
        }),
      2000
    );
  };

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleOnScroll);
    document.removeEventListener('click', this.handleOutsideClick);
    clearTimeout(this.state.welcomeModalID);
  }

  handleOutsideClick = (e: any) => {
    if (this.state.isOpenWelcomeModal) {
      if (
        (this.dropdownWelcomeRef.current && !this.dropdownWelcomeRef.current.contains(e.target)) ||
        this.navbarAuthRef.current === e.target
      ) {
        this.toggleWelcomeModal();
        document.removeEventListener('click', this.handleOutsideClick, false);
      }
    }

    if (
      (this.dropdownAuthRef.current && this.dropdownAuthRef.current.contains(e.target)) ||
      this.navbarAuthRef.current === e.target
    )
      return;
    this.setState({ isOpenDropdownAuth: false }, () => {
      const elementDropdown = document.getElementById('dropdown-auth-mobile');
      const elementDropdownMenu = document.getElementById('dropdown-menu-mobile');
      elementDropdown && elementDropdown.classList.remove('show');
      elementDropdownMenu && elementDropdownMenu.classList.remove('show');
      document.removeEventListener('click', this.handleOutsideClick, false);
    });
  };

  handleOnScroll = () => {
    const element = document.getElementById('mainmenu-navbar');
    if (!element) return;
    if (this.props.isTransparent === false) return;

    if (
      window.pageYOffset >= 80 - this.state.navbarPlaceholderHeight ||
      window.scrollY >= 80 - this.state.navbarPlaceholderHeight
    ) {
      element.classList.remove('transparent');
      element.classList.add('shadow');
    } else {
      element.classList.add('transparent');
      element.classList.remove('shadow');
    }
  };

  onToggleHamburger = () => {
    const { isOpenMenu } = this.state;
    const element = document.getElementById('mainmenu-navbar');
    if (element) {
      this.setState(
        {
          isOpenMenu: !isOpenMenu
        },
        () => {
          if (this.state.isOpenMenu) {
            clearTimeout(this.state.welcomeModalID);
            element.classList.remove('collapsed');
          } else {
            element.classList.add('collapsed');
          }
        }
      );
    }
  };

  onToggleAuth = () => {
    const { isOpenDropdownAuth } = this.state;
    const elementDropdown = document.getElementById('dropdown-auth-mobile');
    const elementDropdownMenu = document.getElementById('dropdown-menu-mobile');

    if (elementDropdown && elementDropdownMenu) {
      this.setState(
        {
          isOpenDropdownAuth: !isOpenDropdownAuth
        },
        () => {
          if (this.state.isOpenDropdownAuth) {
            clearTimeout(this.state.welcomeModalID);
            this.setState({ isOpenWelcomeModal: false });

            elementDropdown.classList.add('show');
            elementDropdownMenu.classList.add('show');
            document.addEventListener('click', this.handleOutsideClick, false);
          } else {
            elementDropdown.classList.remove('show');
            elementDropdownMenu.classList.remove('show');
            document.removeEventListener('click', this.handleOutsideClick);
          }
        }
      );
    }
  };

  toggleWelcomeModal = () => {
    this.setState({ isOpenWelcomeModal: false }, () => {
      document.removeEventListener('click', this.handleOutsideClick);
    });
  };

  pushDataLayer = (type: string) => {
    const bookingBegin = setupDateTime(true, type);
    const bookingEnd = setupDateTime(false, type);

    let label = 'undefined';
    switch (type) {
      case 'undefined':
        label = 'เช่ารถระยะสั้น';
        break;
      case 'local':
        label = 'เช่ารถไม่ใช้บัตรเครดิต';
        break;
      case 'inter':
        label = 'เช่ารถบริษัทรายใหญ่';
        break;
    }
    const args = {
      search_pickup_date: bookingBegin.locale('en').format('YYYY-MMM-DD'),
      search_pickup_time: bookingBegin.locale('en').format('HH:mm:00'),
      search_return_date: bookingEnd.locale('en').format('YYYY-MMM-DD'),
      search_return_time: bookingEnd.locale('en').format('HH:mm:00'),
      search_dealer_type: type,
      event_label: `rental_type_${label}`,
      ...this.dataLayerArgs
    };

    pushDataLayer(args);
  };

  get dataLayerArgs() {
    return {
      event: 'track_event',
      event_category: 'header_section',
      event_action: 'search_bookings_success',
      search_destination: 'เชียงใหม่', // example กรุงเทพมหานคร
      search_pickup_location: 'สนามบินเชียงใหม่', // example สนามบินดอนเมือง
      search_dealer_name: 'undefined', // example thai_rent_a_car, Hertz
      search_car_type: 'undefined', // example eco,suv,van
      search_promotion_name: 'undefined', // example onlybangkok
      search_rental_type: 'shortterm', // example shortterm,longterm,withdriver
      sort_by: 'price', // example price,raiting&price
      search_time_stamp: moment()
        .locale('en')
        .format('YYYY-MMM-DD HH:mm:ss')
    };
  }

  render() {
    const { prefix, isTransparent } = this.props;
    const { auth } = this.context;
    const { isAuth } = auth;
    const host = this.context.legacyHost;
    return (
      <>
        {this.props.sticky && <div style={{ height: this.state.navbarPlaceholderHeight }} />}
        <header id="mainMenu" style={{ position: this.props?.sticky ? 'fixed' : 'initial' }}>
          {/* {section === PageSection.City ||
          section === PageSection.CarWithDriver ||
          section === PageSection.LongTermRental ||
          section === PageSection.Home ? (
            <PromotionBanner
              prefix={prefix}
              setNavbarPlaceHolderHeight={(height) => {
                this.setState({ navbarPlaceholderHeight: height }, () => window.scrollTo(0, 0));
              }}
            />
          ) : (
            <div />
          )} */}
          <PromotionBanner
            prefix={prefix}
            setNavbarPlaceHolderHeight={(height) => {
              this.setState({ navbarPlaceholderHeight: height }, () => window.scrollTo(0, 0));
            }}
          />
          {/* <CookieBox prefix={prefix} /> */}
          <nav
            id="mainmenu-navbar"
            className={`navbar navbar-expand-lg collapsed ${isTransparent === false ? 'shadow' : 'transparent'}`}
          >
            <a
              className="navbar-brand"
              data-event-category="header_section"
              data-event-action="click"
              data-event-label="logo_homepage"
              onClick={(e) => dataLayer(e)}
              href={WEB_LEGACY_LINK}
            >
              <div id="dh-logo"></div>
            </a>
            <div id="navbar-auth">
              <div className="dropdown dropdown--auth" id="dropdown-auth-mobile">
                <button
                  className="p-0"
                  type="button"
                  id="my-account"
                  data-toggle="dropdown"
                  data-event-category="header_section"
                  data-event-action="click"
                  data-event-label="user_icon"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={(e) => {
                    dataLayer(e);
                    this.onToggleAuth();
                  }}
                >
                  <i id="auth-toggler" className="icon-auth" ref={this.navbarAuthRef} />
                </button>
                <div
                  className={`dropdown-menu ${isAuth ? 'authenticated' : ''}`}
                  id="dropdown-menu-mobile"
                  aria-labelledby="my-account"
                  ref={this.dropdownAuthRef}
                >
                  <div>
                    <DropdownAuthSection prefix={prefix} isAuth={isAuth} consumer={auth.consumer} />
                  </div>
                </div>
                <div
                  className={`dropdown-menu ${this.state.isOpenWelcomeModal ? 'show' : ''}`}
                  id="dropdown-menu-welcome"
                  ref={this.dropdownWelcomeRef}
                >
                  <div>
                    <DropdownAuthWelcome prefix={prefix} consumer={auth.consumer} />
                  </div>
                </div>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavDropdown"
                data-event-category="header_section"
                data-event-action="show"
                data-event-label="menu"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={(e) => {
                  dataLayer(e);
                  this.onToggleHamburger();
                }}
              >
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </div>
            <div id="overlay-menu">
              <div className="menu-header"></div>
              <div className="container">
                <ul>
                  <li>
                    <a href="/">หน้าแรก</a>
                  </li>
                  <li>
                    บริการของ Drivehub
                    <ul>
                      <li>
                        <a href={searchPath('rent_short')}>เช่ารถระยะสั้น</a>
                      </li>
                      <li>
                        <a href={`${WEB_LEGACY_LINK}/${prefix}/long-term-rental`}>เช่ารถระยะยาว</a>
                      </li>
                      <li>
                        <a href={`${WEB_LEGACY_LINK}/${prefix}/car-with-driver`}>เช่ารถพร้อมคนขับ</a>
                      </li>
                      <li>
                        <a href={searchPath('rent_local')}>
                          เช่ารถไม่ใช้บัตรเครดิต
                          <span className="badge badge-local badge-sm">
                            <i className="icon-local"></i>LOCAL
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href={searchPath('rent_inter')}>
                          เช่ารถบริษัทรายใหญ่
                          <span className="badge badge-inter badge-sm">
                            <i className="icon-inter"></i>INTER
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    ความช่วยเหลือ
                    <ul>
                      <li>
                        <a
                          // onClick={(e) => sendToGAByEvent(e, 'header_help', 'click')}
                          href={`${this.context.legacyHost}/${prefix}/how-to-book`}
                        >
                          วิธีการจองรถ
                        </a>
                      </li>
                      <li>
                        <a
                          // onClick={(e) => sendToGAByEvent(e, 'header_help', 'click')}
                          href={`${this.context.legacyHost}/${prefix}/documents`}
                        >
                          เอกสารเช่ารถ
                        </a>
                      </li>
                      <li>
                        <a
                          // onClick={(e) => sendToGAByEvent(e, 'header_help', 'click')}
                          href={`${this.context.legacyHost}/${prefix}/insurance`}
                        >
                          ประกันภัย
                        </a>
                      </li>
                      <li>
                        <a
                          // onClick={(e) => sendToGAByEvent(e, 'header_help', 'click')}
                          href={`${this.context.legacyHost}/${prefix}/help-center`}
                        >
                          ความช่วยเหลือเพิ่มเติม
                        </a>
                      </li>
                    </ul>
                  </li>
                  <AuthenticationSectionMobile prefix={prefix} isAuth={isAuth} host={host} />
                  <li>
                    ติดต่อเรา
                    <ul>
                      <li>
                        <a
                          // onClick={() => sendToGAByFixedText('header_contact', 'click', 'line')}
                          rel="nofollow"
                          href="https://lin.ee/axOqULu"
                          className="lineat"
                        >
                          <img
                            src={LineIcon}
                            alt="@drivehub"
                            style={{ margin: '0px 2px', width: '16px', height: '16px' }}
                          />{' '}
                          @drivehub
                        </a>
                      </li>
                      <li>
                        <a
                          // onClick={() => sendToGAByFixedText('header_contact', 'click', 'call')}
                          rel="noreferrer"
                          href="tel:+6620385222"
                          className="call"
                        >
                          <i className="icon-phone"></i> 02-038-5222
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div className="collapse navbar-collapse " id="navbarNavDropdown">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link"
                    href="/"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-toggle="dropdown"
                    data-event-category="header_section"
                    data-event-action="click_menu"
                    data-event-label="เช่ารถกับ Drivehub"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={(e) => dataLayer(e)}
                  >
                    เช่ารถกับ Drivehub
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <div className="dropdown-link">
                      <a
                        className="dropdown-item"
                        href={searchPath('rent_short')}
                        onClick={() => {
                          this.pushDataLayer('undefined');
                        }}
                      >
                        เช่ารถระยะสั้น
                      </a>
                      <a
                        data-event-category="header_section"
                        data-event-action="click"
                        data-event-label="เช่ารถระยะยาว"
                        className="dropdown-item"
                        href={`${WEB_LEGACY_LINK}/${prefix}/long-term-rental`}
                        onClick={(e) => dataLayer(e)}
                      >
                        เช่ารถระยะยาว
                      </a>
                      <a
                        data-event-category="header_section"
                        data-event-action="click"
                        data-event-label="เช่ารถพร้อมคนขับ"
                        className="dropdown-item"
                        href={`${WEB_LEGACY_LINK}/${prefix}/car-with-driver`}
                        onClick={(e) => dataLayer(e)}
                      >
                        เช่ารถพร้อมคนขับ
                      </a>
                      <a
                        className="dropdown-item"
                        href={searchPath('rent_local')}
                        onClick={() => {
                          this.pushDataLayer('local');
                        }}
                      >
                        เช่ารถไม่ใช้บัตรเครดิต
                        <span className="badge badge-local badge-sm">
                          <i className="icon-local"></i>LOCAL
                        </span>
                      </a>
                      <a
                        className="dropdown-item"
                        href={searchPath('rent_inter')}
                        onClick={() => {
                          this.pushDataLayer('inter');
                        }}
                      >
                        เช่ารถบริษัทรายใหญ่
                        <span className="badge badge-inter badge-sm">
                          <i className="icon-inter"></i>INTER
                        </span>
                      </a>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link"
                    href={`${this.context.legacyHost}/${prefix}/help-center`}
                    id="navbarDropdownMenuLink"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-toggle="dropdown"
                    data-event-category="header_section"
                    data-event-action="click_menu"
                    data-event-label="ความช่วยเหลือ"
                    onClick={(e) => dataLayer(e)}
                  >
                    ความช่วยเหลือ
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <div className="dropdown-link">
                      <a
                        className="dropdown-item"
                        data-event-category="header_section"
                        data-event-action="faq"
                        data-event-label="วิธีการจองรถ"
                        onClick={(e) => dataLayer(e)}
                        href={`${this.context.legacyHost}/${prefix}/how-to-book`}
                      >
                        วิธีการจองรถ
                      </a>
                      <a
                        className="dropdown-item"
                        data-event-category="header_section"
                        data-event-action="faq"
                        data-event-label="เอกสารการเช่ารถ"
                        onClick={(e) => dataLayer(e)}
                        href={`${this.context.legacyHost}/${prefix}/documents`}
                      >
                        เอกสารการเช่ารถ
                      </a>
                      <a
                        className="dropdown-item"
                        data-event-category="header_section"
                        data-event-action="faq"
                        data-event-label="ประกันภัย"
                        onClick={(e) => dataLayer(e)}
                        href={`${this.context.legacyHost}/${prefix}/insurance`}
                      >
                        ประกันภัย
                      </a>
                      <a
                        className="dropdown-item"
                        data-event-category="header_section"
                        data-event-action="faq"
                        data-event-label="ความช่วยเหลือเพิ่มเติม"
                        onClick={(e) => dataLayer(e)}
                        href={`${this.context.legacyHost}/${prefix}/help-center`}
                      >
                        ความช่วยเหลือเพิ่มเติม
                      </a>
                    </div>
                  </div>
                </li>
                <AuthenticationSectionDesktop
                  prefix={prefix}
                  isAuth={isAuth}
                  consumer={auth.consumer}
                  isOpenWelcomeModal={this.state.isOpenWelcomeModal}
                  toggleWelcomeModal={this.toggleWelcomeModal}
                  isAuthFetching={auth.fetching}
                  host={host}
                />
              </ul>
              <ul className="navbar-nav contact">
                <li className="nav-item">
                  <a
                    data-event-category="header_section"
                    data-event-action="contact_us"
                    data-event-label="line"
                    className="nav-link lineat"
                    rel="nofollow"
                    href="https://lin.ee/axOqULu"
                    onClick={(e) => dataLayer(e)}
                  >
                    <img src={LineIcon} alt="@drivehub" style={{ margin: '0px 2px', width: '16px', height: '16px' }} />{' '}
                    @drivehub
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    data-event-category="header_section"
                    data-event-action="contact_us"
                    data-event-label="callcenter"
                    className="nav-link call"
                    rel="noreferrer"
                    href="tel:+6620385222"
                    onClick={(e) => dataLayer(e)}
                  >
                    <i className="icon-phone"></i> 02-038-5222
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </header>
      </>
    );
  }
}

import { PureComponent } from 'react';
import AuthInfoBox from '@shares/auth-info-box';
import { AuthInfoBoxInitailFormValues } from '@shares/auth-info-box/interface';
import { deleteCookie, readCookie } from '@utils';
import { ErrorBox } from '@shares/error-box';
import Modal from 'react-bootstrap/Modal';
import './style.scss';

class RegisterModal extends PureComponent<{ prefix: string }, { visible: boolean; isError: boolean }> {
  state = {
    visible: false,
    isError: false
  };

  componentDidMount() {
    this.checkRegisterModal();
  }

  checkRegisterModal = () => {
    const registerModal = readCookie('_registerModal');
    if (registerModal) return this.setState({ visible: true });
  };

  onSubmitForm = () => {
    deleteCookie('_registerModal');
    this.toggleModalRegister();
  };

  get initailFormValues(): AuthInfoBoxInitailFormValues {
    const defaultEmail = readCookie('rg_email') || '';
    return {
      first_name: '',
      last_name: '',
      phone_number: '',
      email: defaultEmail
    };
  }
  toggleModalRegister = () => {
    this.setState({ visible: !this.state.visible });
  };

  render() {
    const { visible, isError } = this.state;
    const { prefix } = this.props;
    return (
      <Modal
        show={visible}
        onHide={this.toggleModalRegister}
        centered
        className="modal-register"
        dialogClassName="modal-dialog--register"
        scrollable
        onExit={() => deleteCookie('_registerModal')}
      >
        <Modal.Header className="modal-header--register d-lg-none">
          <Modal.Title className="text-center">
            ยินดีต้อนรับ
            <i
              className="icon-close"
              onClick={() => {
                // sendToGAByFixedText('welcome_info', 'not_save', 'close');
                this.toggleModalRegister;
              }}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 position-relative">
          <ErrorBox isError={isError} hideDesktop />
          <i id="new-user-welcome-close" className="icon-close d-none d-lg-block" onClick={this.toggleModalRegister} />
          <div className="text-center px-3 py-3 pt-lg-5">
            <h3>บัญชีของคุณพร้อมใช้งานแล้ว!</h3>
            <p>ข้อมูลนี้จะถูกใช้เพื่อป้อนรายละเอียดของคุณโดยอัตโนมัติเพื่อให้คุณสามารถทำการจองได้อย่างรวดเร็ว</p>
          </div>
          <AuthInfoBox
            prefix={prefix}
            onSubmit={this.onSubmitForm}
            initailFormValues={this.initailFormValues}
            disabledEmailField
            isError={isError}
            className="register-modal-form"
          />
          <p
            id="new-user-welcome-skip"
            className="text-center"
            onClick={() => {
              // sendToGAByFixedText('welcome_info', 'not_save', 'skip');
              deleteCookie('_registerModal');
              this.toggleModalRegister();
            }}
            style={{
              color: '#0078ff',
              padding: '1rem 1rem',
              cursor: 'pointer'
            }}
          >
            ข้ามไปก่อน
          </p>
        </Modal.Body>
      </Modal>
    );
  }
}

export default RegisterModal;

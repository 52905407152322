import React, { useEffect, useRef, FunctionComponent } from 'react';
import Alert from 'react-bootstrap/Alert';
import { WEB_LEGACY_LINK } from '../../config';

interface PromotionBannerProps {
  prefix: string;
  visible: boolean;
  setParentHeight: (n: number) => void;
  toggleVisible: () => void;
}

export const PromotionWelcome: FunctionComponent<PromotionBannerProps> = ({
  prefix,
  toggleVisible,
  setParentHeight
}) => {
  const r = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let height = 0;
    if (r.current && r.current.clientHeight) {
      height = r.current.clientHeight;
    }
    setParentHeight(height);
  }, []);
  return (
    <Alert show={true} onClose={toggleVisible} className="promotion-banner m-0 rounded-0" ref={r}>
      <p className="text-center">
        <strong>ลูกค้าใหม่ ลดทันที 100 บาท*</strong>
      </p>
      <p>
        เพียงสมัครสมาชิก ภายใน 10 เมษายน 2021{' '}
        <a className="d-inline" href={`${WEB_LEGACY_LINK}/${prefix}/promotions/drivehub-welcome-member-2021`}>
          อ่านรายละเอียด
        </a>
      </p>
    </Alert>
  );
};

PromotionWelcome.displayName = 'PromotionWelcome';

import { WEB_SEARCH_LINK, COOKIE_HOST } from '@config';
import moment, { Moment } from 'moment-timezone';
import { PROMOTION_DETAIL_EVENT_CATEGORY } from '@templates/promotions/tracking';

// <----------------- FYI ----------------->
// SECTION = [ SEARCHING, LOCAL_STORAGE, COOKIE, TRACKING]
// Search Each Section =>  SECTION: xxx
// <----------------- FYI ----------------->

const roundUpTime = (dateTime: Moment) => {
  const minute = dateTime.minutes();
  if (minute <= 30) {
    const diffTime = 30 - minute;
    return dateTime.add(diffTime, 'minutes');
  } else {
    const diffTime = 60 - minute;
    return dateTime.add(diffTime, 'minutes');
  }
};

const checkTimeCondition = (dateTime: Moment) => {
  const hour = dateTime.hours();
  if ([18, 19].includes(hour)) {
    return dateTime.set({ hour: 22, minute: 0 });
  } else if (hour >= 20) {
    return dateTime.add({ days: 1 }).set({ hour: 10, minute: 0 });
  } else if (hour <= 6) {
    return dateTime.set({ hour: 10, minute: 0 });
  } else {
    return dateTime.add({ hours: 4 });
  }
};

export const setupDateTime = (isPickup: boolean, type?: string) => {
  const currentDateTime = roundUpTime(moment().tz('Asia/Bangkok'));
  const pickupDateTime = checkTimeCondition(currentDateTime);

  if (isPickup) {
    if (type === 'inter') return pickupDateTime.add(5, 'days');
    return pickupDateTime;
  } else {
    if (type === 'inter') return pickupDateTime.add(7, 'days');
    return pickupDateTime.add(2, 'days');
  }
};

export const canUseDOM = () => {
  // Stop : To prevent SSR when call document
  const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
  if (!canUseDOM) return false;
  return true;
  // Stop : To prevent SSR when call document
};

export const sha256 = async (message: string) => {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);
  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  // convert bytes to hex string
  const hashHex = hashArray.map((b) => ('00' + b.toString(16)).slice(-2)).join('');
  return hashHex;
};

export const appendParamsCurrentPage = (url: string) => {
  if (!canUseDOM()) {
    return url;
  }

  let params = window.location.search;

  if (url.includes('?')) {
    params = params.replace('?', '&');
  }

  return url + params;
};

// SECTION: SEARCHING

type DrivehubService = 'rent_short' | 'rent_inter' | 'rent_local';

export const searchPath = (service: DrivehubService) => {
  const bookingBegin = setupDateTime(true).format('YYYY-MM-DD HH:mm');
  const bookingEnd = setupDateTime(false).format('YYYY-MM-DD HH:mm');

  const bookingBeginInter = setupDateTime(true, 'inter').format('YYYY-MM-DD HH:mm');
  const bookingEndInter = setupDateTime(false, 'inter').format('YYYY-MM-DD HH:mm');

  let searchURL = '';

  switch (service) {
    case 'rent_short': {
      searchURL =
        WEB_SEARCH_LINK +
        '?&open_search=true&location_id=1&booking_begin=' +
        bookingBegin +
        '&booking_end=' +
        bookingEnd;
      break;
    }
    case 'rent_inter': {
      searchURL =
        WEB_SEARCH_LINK +
        '?&open_search=true&location_id=1&booking_begin=' +
        bookingBeginInter +
        '&booking_end=' +
        bookingEndInter +
        '&sources=inter';
      break;
    }
    case 'rent_local':
      searchURL =
        WEB_SEARCH_LINK +
        '?&open_search=true&location_id=1&booking_begin=' +
        bookingBegin +
        '&booking_end=' +
        bookingEnd +
        '&sources=local';
      break;
    default:
      searchURL = '#';
      break;
  }

  const url = appendParamsCurrentPage(searchURL);
  return url;
};
// SECTION: LOCAL_STORAGE
export const readLocalStorage = (key: string) => {
  // Start : To prevent SSR when call document
  const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
  if (!canUseDOM) return null;
  return window.localStorage.getItem(key);
};

export const setLocalStorage = (key: string, value: string) => {
  const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
  if (!canUseDOM) return null;
  return window.localStorage.setItem(key, value);
};

export const deleteLocalStorage = (key: string) => {
  const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
  if (!canUseDOM) return null;
  return window.localStorage.removeItem(key);
};

// SECTION: COOKIE

export const readCookie = (name: string) => {
  const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
  if (!canUseDOM) return null;
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const setCookie = (cname: string, cvalue: any, exdays: number, cpath: string, host = COOKIE_HOST) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + `;path=${cpath}` + `;domain=${host}`;
};

export const setCookieMinutes = (
  cname: string,
  cvalue: any,
  _: number,
  cpath: string,
  minutes: number,
  host = COOKIE_HOST
) => {
  const date = new Date();
  date.setTime(date.getTime() + minutes * 60 * 1000);
  const expires = '; expires=' + date.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + `;path=${cpath}` + `;domain=${host}`;
};

export const deleteCookie = (cname: string, host = COOKIE_HOST) => {
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${host}`;
};

export const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

// SECTION: TRACKING

export enum PageSection {
  Home,
  Promotion,
  PromotionContent,
  City,
  Inter,
  CarWithDriver,
  Help,
  LongTermRental,
  Login,
  Register,
  Forgot,
  User,
  ExtendRental
}
interface DataLayerOBJ {
  event: string;
  event_category?: string;
  event_action?: string;
  event_label?: string;
  [key: string]: any;
}

interface GetEventOBJProps {
  event_category?: string;
  event_label?: string;
}
const getEventOBJ = (event: any): GetEventOBJProps => {
  if (!event)
    return {
      event_category: '',
      event_label: ''
    };
  const eventObj = {};
  const label = event.currentTarget.getAttribute('data-event-label');
  const category = event.currentTarget.getAttribute('data-event-category');
  if (label) {
    eventObj['event_label'] = label;
  }
  if (category) {
    eventObj['event_category'] = category;
  }
  return eventObj;
};

export const sendToDataLayer = (
  e: any,
  custom?: { [key: string]: any },
  customEvent?: string,
  customAction?: string
) => {
  if (!window.dataLayer) return;
  const dataOBJ = getEventOBJ(e);

  const obj: DataLayerOBJ = {
    event: customEvent ? customEvent : 'track_event',
    event_action: customAction ? customAction : 'click',
    ...dataOBJ,
    ...custom
  };
  window.dataLayer.push(obj);
};

export const getTrackingTimestamp = (): string => {
  return moment().locale('en').format('YYYY-MMM-DD HH:mm:ss');
};

export const convertToDateAndTime = (dateTime: string): { date: string; time: string } => {
  return {
    date: moment(dateTime).locale('en').format('YYYY-MMM-DD'),
    time: moment(dateTime).locale('en').format('HH:mm:ss')
  };
};

const HOME_PAGE_EVENT_CATEGORY = 'homepage_section';
const PROMOTION_PAGE_EVENT_CATEGORY = 'promotion_section';
const INTER_PAGE_EVENT_CATEGORY = 'dealer_search_section';
const CITY_PAGE_EVENT_CATEGORY = 'destination_search_section';
const LONG_TERM_RENTAL_PAGE_EVENT_CATEGORY = 'longterm_section';
const CAR_WITH_DRIVER_PAGE_EVENT_CATEGORY = 'rental_with_driver_section';
const LOGIN_PAGE_EVENT_CATEGORY = 'login_section';
const REGISTER_PAGE_EVENT_CATEGORY = 'register_section';
const USER_PAGE_EVENT_CATEGORY = 'profile_section';

export const getDefaultEventCategory = (sectionName: PageSection, options?: { customCategory: string }) => {
  if (options) {
    return options.customCategory;
  }
  switch (sectionName) {
    case PageSection.Home:
      return HOME_PAGE_EVENT_CATEGORY;
    case PageSection.Inter:
      return INTER_PAGE_EVENT_CATEGORY;
    case PageSection.Promotion:
      return PROMOTION_DETAIL_EVENT_CATEGORY;
    case PageSection.PromotionContent:
      return PROMOTION_PAGE_EVENT_CATEGORY;
    case PageSection.City:
      return CITY_PAGE_EVENT_CATEGORY;
    case PageSection.LongTermRental:
      return LONG_TERM_RENTAL_PAGE_EVENT_CATEGORY;
    case PageSection.CarWithDriver:
      return CAR_WITH_DRIVER_PAGE_EVENT_CATEGORY;
    case PageSection.Login:
      return LOGIN_PAGE_EVENT_CATEGORY;
    case PageSection.Register:
      return REGISTER_PAGE_EVENT_CATEGORY;
    case PageSection.User:
      return USER_PAGE_EVENT_CATEGORY;
    default:
      return 'undefined';
  }
};

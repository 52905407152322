import { navigate } from 'gatsby';
import { FunctionComponent } from 'react';
import Button from 'react-bootstrap/Button';
import './style.scss';

export enum AlertType {
  Success,
  Error,
  Warning
}
interface TypeProps {
  icon: string;
  bg: string;
}

interface AlertBoxProps {
  visible: boolean;
  type?: AlertType;
  customText?: string | React.ReactNode;
}

const getTypeProperties = (type?: AlertType): TypeProps => {
  switch (type) {
    case AlertType.Success:
      return {
        icon: 'icon-ok-circle',
        bg: 'bg-alert-success'
      };
    default:
      return {
        icon: 'icon-cancel-circle',
        bg: 'bg-alert-error'
      };
  }
};
export const AlertBox: FunctionComponent<AlertBoxProps> = ({ visible, type, customText }) => {
  const typeProps = getTypeProperties(type);
  return (
    <div className={`alert-box ${visible ? 'visible' : ''} ${typeProps.bg}`}>
      <div>
        <i className={typeProps.icon} />
        {type === AlertType.Success ? (
          customText ? (
            customText
          ) : (
            <>
              บันทึกสำเร็จแล้ว <span onClick={() => navigate('/')}>กลับไปหน้าแรก</span>
            </>
          )
        ) : customText ? (
          customText
        ) : (
          'ระบบเกิดข้อผิดพลาด โปรดลองใหม่อีกครั้ง'
        )}
      </div>
    </div>
  );
};

interface VerifyBoxProp {
  visible: boolean;
  onClick: () => void;
  type?: AlertType;
}
export const VerifyBox: FunctionComponent<VerifyBoxProp> = ({ visible, onClick }) => {
  return (
    <div className={`alert-box alert-box--verify ${visible ? 'visible' : ''}`}>
      <div>
        <img src={'/assets/icons/verify-warning.svg'} />
        <p className="d-flex flex-column m-0">
          บัญชียังไม่ได้รับการยืนยันอีเมล เพื่อเพิ่มความปลอดภัยให้กับบัญชีของคุณโปรดกดยืนยันจากอีเมลที่คุณได้รับ
          หากไม่ได้รับ
          <Button variant="outline-primary" className="btn--verify" onClick={onClick}>
            ส่งอีเมลยืนยันอีกครั้ง
          </Button>
        </p>
      </div>
    </div>
  );
};

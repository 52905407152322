import axios from 'axios';
import queryString from 'query-string';
import { DRIVEHUB_API, WEB_LEGACY_LINK } from '../../config';
import {
  setCookie,
  deleteCookie,
  readCookie,
  setLocalStorage,
  appendParamsCurrentPage,
  setCookieMinutes
} from '../utils';
import jwt from 'jsonwebtoken';
import { canUseDOM } from '../utils';

const navigate = (url: string) => (window.location.href = url);

// const createError = (status: number, message: string) => ({ status, message });
interface GoogleProfile {
  id: string;
  givenName: string;
  familyName: string;
  email: string;
  connectedAt: string;
}
interface FacebookProfile {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  connectedAt: string;
}
export interface ConsumerInfo {
  id: number;
  status: string;
  firstName: string;
  lastName: string;
  fullName: string;
  phoneNumber: string;
  email: string;
  totalIncomingBookings: number;
  facebookUid: string;
  googleUid: string;
  source: string;
  googleProfile?: GoogleProfile;
  facebookProfile?: FacebookProfile;
  isPasswordSet: boolean;
  isVerified: boolean;
}

export type BookingState = 'pending' | 'cancelled' | 'incoming' | 'finished';
export interface UserBookings {
  source: string;
  booking_id: number;
  status: string;
  pickup_at: string;
  pickup_datetime: string;
  return_at: string;
  return_datetime: string;
  img_url: string;
  state_name_th: string;
  state_name_en: string;
  first_name: string;
  last_name: string;
}
const CONSUMER_TOKEN_EXP = 60;
const onClickLogin = (prefix: string) => {
  const rootRef = readCookie('_where');
  if (!rootRef) {
    setCookie('_where', window.location.href, 60, '/');
  }
  navigate(appendParamsCurrentPage(`${WEB_LEGACY_LINK}/${prefix}/user/login`));
};
const onClickLogout = (skipRedirect?: boolean) => {
  deleteCookie('_consumerID');
  deleteCookie('_consumerToken');
  deleteCookie('_totalIncomingBookings');
  deleteCookie('_where');
  setCookie('logout', true, CONSUMER_TOKEN_EXP, '/');
  if (skipRedirect) return;
  window.location.href = appendParamsCurrentPage(WEB_LEGACY_LINK);
};
const onClickRegister = (prefix: string) => {
  const rootRef = readCookie('_where');
  if (!rootRef) {
    setCookie('_where', window.location.href, 60, '/');
  }
  navigate(appendParamsCurrentPage(`${WEB_LEGACY_LINK}/${prefix}/user/register`));
};

const authValidate = async (key: string, value: string, email?: string) => {
  const params = {
    [key]: value
  };
  if (email) params['email'] = email;
  params['email'] = params['email'].toLowerCase();

  return await axios
    .get<{ valid: boolean; value: string; source: string }>(`${DRIVEHUB_API}/v1/auth/validate`, {
      params: params
    })
    .then((result) => {
      return result.data;
    })
    .catch((e) => {
      throw e.response ? e.response : new Error(e);
    });
};

const authRegister = async (email: string, password: string, isAcceptedNewsletter: boolean) => {
  let utm_campaign = '';
  if (canUseDOM()) {
    const { query } = queryString.parseUrl(window.location.href);
    if (query.utm_campaign) {
      utm_campaign = query.utm_campaign as string;
    }
  }
  return await axios({
    method: 'post',
    url: `${DRIVEHUB_API}/v1/auth/sign_up`,
    headers: { 'content-type': 'application/json' },
    data: {
      email: email,
      password: password,
      is_accepted_newsletter: isAcceptedNewsletter,
      _ga: readCookie('_ga'),
      utm_campaign
    }
  })
    .then(({ data }) => {
      // sendToGAByFixedText('account', 'sign_up', 'email');
      setCookie('_consumerID', data.id, 60, '/');
      setCookie('_consumerToken', data.token, CONSUMER_TOKEN_EXP, '/');
      setCookie('_registerModal', true, 60, '/');
      setCookie('rg_email', email, 24, '/');
      return;
    })
    .catch((e) => {
      throw e.response ? e.response : new Error(e);
    });
};

const authLogin = async (email: string, password: string) => {
  console.log('DRIVEHUB_API', DRIVEHUB_API);
  let utm_campaign = '';
  if (canUseDOM()) {
    const { query } = queryString.parseUrl(window.location.href);
    if (query.utm_campaign) {
      utm_campaign = query.utm_campaign as string;
    }
  }
  return await axios({
    method: 'post',
    url: `${DRIVEHUB_API}/v1/auth/sign_in`,
    headers: { 'content-type': 'application/json' },
    data: {
      email: email.toLowerCase(),
      password: password,
      utm_campaign
    }
  })
    .then(({ data }) => {
      // sendToGAByFixedText('account', 'sign_in', 'email');
      setCookie('_consumerID', data.id, 60, '/');
      setCookie('_consumerToken', data.token, CONSUMER_TOKEN_EXP, '/');
      // setCookie('_welcomeModal', true, 24, '/');
      deleteCookie('logout');
      return;
    })
    .catch((e) => {
      throw e.response ? e.response : new Error(e);
    });
};

const authUpdateInfo = async (data: {
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  email?: string;
}) => {
  const consumerToken = readCookie('_consumerToken');
  // if (!consumerToken) return Promise.reject(createError(401, 'Unauthorized'));
  if (!consumerToken) return Promise.reject({ status: 401 });

  return await axios({
    method: 'put',
    url: `${DRIVEHUB_API}/v1/consumer`,
    headers: {
      'content-type': 'application/json',
      Authorization: consumerToken
    },
    data: {
      ...data
    }
  })
    .then(({ data }) => {
      deleteCookie('_registerModal');
      setCookie('_welcomeModal', true, 24, '/');
      setCookie('_consumerID', data.id, CONSUMER_TOKEN_EXP, '/');
      setCookie('_consumerToken', data.token, CONSUMER_TOKEN_EXP, '/');
      return;
    })
    .catch((e) => {
      throw e.response ? e.response : new Error(e);
    });
};

const toCamel = (str: string) => {
  return str.replace(/([-_][a-z])/gi, ($1) => {
    return $1
      .toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

const isObject = function(obj: any) {
  return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function';
};

const objectSnakeCaseToCamelCase = (obj: any): any => {
  if (isObject(obj)) {
    const n = {};

    Object.keys(obj).forEach((k) => {
      n[toCamel(k)] = objectSnakeCaseToCamelCase(obj[k]) as any;
    });

    return n as any;
  } else if (Array.isArray(obj)) {
    return obj.map((i) => {
      return objectSnakeCaseToCamelCase(i) as any;
    }) as any;
  }

  return obj as any;
};

const parseToken = (token: string) => {
  const data = jwt.decode(token);
  return objectSnakeCaseToCamelCase(data);
};

const fetchTotalIncomingAndSetCookie = async () => {
  const consumerToken = readCookie('_consumerToken');
  if (!consumerToken) {
    setCookieMinutes('_totalIncomingBookings', 0, 1, '/', 10);
    return 0;
  }
  const totalIncomingBookings = await axios
    .get(`${DRIVEHUB_API}/v1/consumer/total_incoming_bookings`, {
      headers: {
        Authorization: consumerToken
      }
    })
    .then(({ data }) => data.total_incomming_bookings)
    .catch((error) => {
      console.log(error);
      return 0;
    });

  setCookieMinutes('_totalIncomingBookings', totalIncomingBookings, 1, '/', 10);
  return totalIncomingBookings;
};

const authTotalIncomingBookings = async () => {
  let totalIncomingBookings = readCookie('_totalIncomingBookings');
  if (!totalIncomingBookings) {
    totalIncomingBookings = await fetchTotalIncomingAndSetCookie();
  }
  return totalIncomingBookings ? parseInt(totalIncomingBookings) : 0;
};

const authMyInfo = async () => {
  const consumerToken = readCookie('_consumerToken');
  if (!consumerToken) return Promise.reject({ status: 401 });

  const data = parseToken(consumerToken) as ConsumerInfo;

  if (!(Object as any).hasOwn(data, 'voucherifyId')) {
    return Promise.reject({ status: 401 });
  }

  if (data) {
    setLocalStorage('consumer_first_name', data.firstName);
    setLocalStorage('consumer_last_name', data.lastName);
    setLocalStorage('consumer_phone_number', data.phoneNumber);
    setLocalStorage('consumer_email', data.email);
    setLocalStorage('consumer_verified', `${data.isVerified}`);
    setCookie('_consumerID', data.id, CONSUMER_TOKEN_EXP, '/');
  }
  await authTotalIncomingBookings();
  return data;
};

const authMyBooking = () => {
  const consumerToken = readCookie('_consumerToken');
  // if (!consumerToken) return Promise.reject(createError(401, 'Unauthorized'));
  if (!consumerToken) return Promise.reject({ status: 401 });

  return axios({
    method: 'get',
    url: `${DRIVEHUB_API}/v1/consumer/bookings`,
    headers: {
      Authorization: consumerToken
    }
  })
    .then(({ data }) => {
      fetchTotalIncomingAndSetCookie();
      return data;
    })
    .catch((e) => {
      throw e.response ? e.response : new Error(e);
    });
};

const authForgotPassword = async (email: string) => {
  return await axios({
    method: 'put',
    url: `${DRIVEHUB_API}/v1/auth/password/forgot`,
    headers: {
      'content-type': 'application/json'
    },
    data: {
      email: email.toLowerCase()
    }
  }).catch((e) => {
    throw e.response ? e.response : new Error(e);
  });
};

const authResetPassword = async (email: string, password: string, resetPasswordToken: string) => {
  return await axios({
    method: 'put',
    url: `${DRIVEHUB_API}/v1/auth/password/reset`,
    headers: {
      'content-type': 'application/json'
    },
    data: {
      email: email.toLowerCase(),
      password: password,
      reset_password_token: resetPasswordToken
    }
  })
    .then(({ data }) => {
      setCookie('_consumerToken', data.token, CONSUMER_TOKEN_EXP, '/');
      return;
    })
    .catch((e) => {
      throw e.response ? e.response : new Error(e);
    });
};

const authSetPassword = (password: string) => {
  const consumerToken = readCookie('_consumerToken');
  // if (!consumerToken) return Promise.reject(createError(401, 'Unauthorized'));
  if (!consumerToken) return Promise.reject({ status: 401 });
  return axios
    .put(
      `${DRIVEHUB_API}/v1/auth/password/set`,
      {
        password: password
      },
      {
        headers: {
          Authorization: consumerToken
        }
      }
    )
    .then(({ data }) => setCookie('_consumerToken', data.token, CONSUMER_TOKEN_EXP, '/'))
    .catch((e) => {
      throw e.response ? e.response : new Error(e);
    });
};

const authChangePassword = (oldPassword: string, newPassword: string) => {
  const consumerToken = readCookie('_consumerToken');
  // if (!consumerToken) return Promise.reject(createError(401, 'Unauthorized'));
  if (!consumerToken) return Promise.reject({ status: 401 });

  return axios({
    method: 'put',
    url: `${DRIVEHUB_API}/v1/auth/password/change`,
    headers: {
      Authorization: consumerToken
    },
    data: {
      old_password: oldPassword,
      new_password: newPassword
    }
  })
    .then(({ data }) => {
      setCookie('_consumerToken', data.token, CONSUMER_TOKEN_EXP, '/');
    })
    .catch((e) => {
      throw e.response ? e.response : new Error(e);
    });
};

const authSocialDisconnectFacebook = () => {
  const consumerToken = readCookie('_consumerToken');
  // if (!consumerToken) return Promise.reject(createError(401, 'Unauthorized'));
  if (!consumerToken) return Promise.reject({ status: 401 });

  return axios({
    method: 'put',
    url: `${DRIVEHUB_API}/v1/auth/social-networks/disconnect/facebook`,
    headers: {
      Authorization: consumerToken
    }
  })
    .then(({ data }) => {
      fetchTotalIncomingAndSetCookie();
      setCookie('_consumerToken', data.token, CONSUMER_TOKEN_EXP, '/');
    })
    .catch((e) => {
      throw e.response ? e.response : new Error(e);
    });
};

const authSocialDisconnectGoogle = () => {
  const consumerToken = readCookie('_consumerToken');
  // if (!consumerToken) return Promise.reject(createError(401, 'Unauthorized'));
  if (!consumerToken) return Promise.reject({ status: 401 });

  return axios({
    method: 'put',
    url: `${DRIVEHUB_API}/v1/auth/social-networks/disconnect/google`,
    headers: {
      Authorization: consumerToken
    }
  })
    .then(({ data }) => {
      fetchTotalIncomingAndSetCookie();
      setCookie('_consumerToken', data.token, CONSUMER_TOKEN_EXP, '/');
    })
    .catch((e) => {
      throw e.response ? e.response : new Error(e);
    });
};

export {
  authValidate,
  authRegister,
  authLogin,
  onClickLogin,
  onClickLogout,
  onClickRegister,
  authUpdateInfo,
  authMyInfo,
  authMyBooking,
  authForgotPassword,
  authResetPassword,
  authChangePassword,
  authSetPassword,
  authTotalIncomingBookings,
  authSocialDisconnectFacebook,
  authSocialDisconnectGoogle
};

import { WEB_SEARCH_LINK, COOKIE_HOST } from '../../config';
import moment, { Moment } from 'moment-timezone';
moment.tz('Asia/Bangkok');

const roundUpTime = (dateTime: Moment) => {
  const minute = dateTime.minutes();
  if (minute <= 30) {
    const diffTime = 30 - minute;
    return dateTime.add(diffTime, 'minutes');
  } else {
    const diffTime = 60 - minute;
    return dateTime.add(diffTime, 'minutes');
  }
};

const checkTimeCondition = (dateTime: Moment) => {
  const hour = dateTime.hours();
  if ([18, 19].includes(hour)) {
    return dateTime.set({ hour: 22, minute: 0 });
  } else if (hour >= 20) {
    return dateTime.add({ days: 1 }).set({ hour: 10, minute: 0 });
  } else if (hour <= 6) {
    return dateTime.set({ hour: 10, minute: 0 });
  } else {
    return dateTime.add({ hours: 4 });
  }
};

export const setupDateTime = (isPickup: boolean, type?: string) => {
  const currentDateTime = roundUpTime(moment());
  const pickupDateTime = checkTimeCondition(currentDateTime);

  if (isPickup) {
    if (type === 'inter') return pickupDateTime.add(5, 'days');
    return pickupDateTime;
  } else {
    if (type === 'inter') return pickupDateTime.add(7, 'days');
    return pickupDateTime.add(2, 'days');
  }
};

export const calculateDateTimeLeft = (expireTimestamp: number) => {
  const timestamp = expireTimestamp / 1000;
  const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
  const expireDateTime = moment.unix(timestamp).format(dateTimeFormat);
  const currentDateTime = moment().format(dateTimeFormat);
  const calDiffDateTime = moment(expireDateTime).diff(currentDateTime);
  return moment.duration(calDiffDateTime);
};

export const canUseDOM = () => {
  // Stop : To prevent SSR when call document
  const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
  if (!canUseDOM) return false;
  return true;
  // Stop : To prevent SSR when call document
};

export const appendParamsCurrentPage = (url: string) => {
  if (!canUseDOM()) {
    return url;
  }

  let params = window.location.search;

  if (url.includes('?')) {
    params = params.replace('?', '&');
  }

  return url + params;
};

// REMARK: SEARCHING

type DrivehubService = 'rent_short' | 'rent_inter' | 'rent_local';

export const searchPath = (service: DrivehubService) => {
  const bookingBegin = setupDateTime(true).format('YYYY-MM-DD HH:mm');
  const bookingEnd = setupDateTime(false).format('YYYY-MM-DD HH:mm');

  const bookingBeginInter = setupDateTime(true, 'inter').format('YYYY-MM-DD HH:mm');
  const bookingEndInter = setupDateTime(false, 'inter').format('YYYY-MM-DD HH:mm');

  let searchURL = '';

  switch (service) {
    case 'rent_short': {
      searchURL =
        WEB_SEARCH_LINK +
        '?&open_search=true&location_id=1&booking_begin=' +
        bookingBegin +
        '&booking_end=' +
        bookingEnd;
      break;
    }
    case 'rent_inter': {
      searchURL =
        WEB_SEARCH_LINK +
        '?&open_search=true&location_id=1&booking_begin=' +
        bookingBeginInter +
        '&booking_end=' +
        bookingEndInter +
        '&sources=inter';
      break;
    }
    case 'rent_local':
      searchURL =
        WEB_SEARCH_LINK +
        '?&open_search=true&location_id=1&booking_begin=' +
        bookingBegin +
        '&booking_end=' +
        bookingEnd +
        '&sources=local';
      break;
    default:
      searchURL = '#';
      break;
  }

  const url = appendParamsCurrentPage(searchURL);
  return url;
};

// REMARK: GA

// type EventCategory =
//   | 'header_service'
//   | 'header_help'
//   | 'header_contact'
//   | 'hamburger'
//   | 'auth_nav'
//   | 'contact_option'
//   | 'interaction'
//   | 'promotion'
//   | 'car_deals'
//   | 'car_today'
//   | 'partner'
//   | 'service'
//   | 'city'
//   | 'footer_service'
//   | 'footer_help'
//   | 'footer_contact'
//   | 'footer_social'
//   | 'footer_condition'
//   | 'footer_about'
//   | 'status'
//   | 'value_prop'
//   | 'account'
//   | 'welcome_info'
//   | 'mobile_app_dummy';

// export type EventAction =
//   | 'click'
//   | 'view'
//   | 'copy'
//   | 'close'
//   | 'apply'
//   | 'redeem'
//   | 'sign_in'
//   | 'sign_up'
//   | 'logout'
//   | 'not_save'
//   | 'back_sign_up'
//   | 'download'
//   | 'subscribe';

// type EventLabel =
//   | 'line'
//   | 'call'
//   | 'view'
//   | 'close'
//   | 'search'
//   | 'see_all'
//   | 'เช่ารถระยะสั้น'
//   | 'เช่ารถระยะยาว'
//   | 'เช่ารถพร้อมคนขับ'
//   | 'เช่ารถไม่ใช้บัตรเครดิต'
//   | 'เช่ารถบริษัทรายใหญ่'
//   | 'status_check'
//   | 'date_pickup'
//   | 'date_return'
//   | 'time_pickup'
//   | 'time_return'
//   | 'previous'
//   | 'next'
//   | 'email'
//   | 'skip'
//   | 'ios'
//   | 'android'
//   | 'google'
//   | 'facebook'
//   | 'skip';

// function sendToGA(eventCategory: EventCategory, eventAction: EventAction, eventLabel: string) {
//   window['ga']('send', 'event', {
//     eventCategory: eventCategory,
//     eventAction: eventAction,
//     eventLabel: eventLabel
//   });
// }

// export function sendToGAByFixedText(eventCategory: EventCategory, eventAction: EventAction, eventLabel: EventLabel) {
//   sendToGA(eventCategory, eventAction, eventLabel);
// }

// export function sendToGAByEvent(e: any, eventCategory: EventCategory, eventAction: EventAction) {
//   sendToGA(eventCategory, eventAction, e.target.innerText);
// }

// export function sendToGAByFreeText(eventCategory: EventCategory, eventAction: EventAction, text: string) {
//   sendToGA(eventCategory, eventAction, text);
// }

// export function sendToGaByFreeStyle(category: any, action: any, label: any) {
//   sendToGA(category, action, label);
// }

// export function sendToGAByManual(eventCategory: string, eventAction: EventAction, text: string) {
//   window['ga']('send', 'event', {
//     eventCategory: eventCategory,
//     eventAction: eventAction,
//     eventLabel: text
//   });
// }

export const readLocalStorage = (key: string) => {
  // Start : To prevent SSR when call document
  const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
  if (!canUseDOM) return null;
  // Stop : To prevent SSR when call document
  return window.localStorage.getItem(key);
};

export const setLocalStorage = (key: string, value: string) => {
  // Start : To prevent SSR when call document
  const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
  if (!canUseDOM) return null;
  // Stop : To prevent SSR when call document
  return window.localStorage.setItem(key, value);
};

export const deleteLocalStorage = (key: string) => {
  // Start : To prevent SSR when call document
  const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
  if (!canUseDOM) return null;
  // Stop : To prevent SSR when call document
  return window.localStorage.removeItem(key);
};

// REMARK: COOKIE

export const readCookie = (name: string) => {
  // Stop : To prevent SSR when call document
  const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
  if (!canUseDOM) return null;
  // Stop : To prevent SSR when call document
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const setCookie = (cname: string, cvalue: any, exdays: number, cpath: string, host = COOKIE_HOST) => {
  if (!canUseDOM()) return;
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = `${cname}=${cvalue};${expires};path=${cpath};`;
};

export const setCookieMinutes = (
  cname: string,
  cvalue: any,
  _: number,
  cpath: string,
  minutes: number,
  host = COOKIE_HOST
) => {
  if (!canUseDOM()) return;
  const date = new Date();
  date.setTime(date.getTime() + minutes * 60 * 1000);
  const expires = '; expires=' + date.toUTCString();
  document.cookie = `${cname}=${cvalue};${expires};path=${cpath};domain=${host}`;
};

export const deleteCookie = (cname: string, host = COOKIE_HOST) => {
  if (!canUseDOM()) return;
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${host}`;
};

export const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

import React, { ReactNodeArray, useEffect, useState } from 'react';
import { calculateDateTimeLeft } from '../utils/index';
import './style.scss';
import translate from './translate.json';

interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

interface CountDownProps {
  prefix: string;
  expireTimestamp: number;
  onCountDown: (timeLeft: TimeLeft) => void;
}

export const CountDown: React.FunctionComponent<CountDownProps> = (props) => {
  const calculateTimeLeft = (): TimeLeft => {
    const differenceDateTime = calculateDateTimeLeft(props.expireTimestamp);

    const timeLeftObj = {
      days: differenceDateTime.days(),
      hours: differenceDateTime.hours(),
      minutes: differenceDateTime.minutes(),
      seconds: differenceDateTime.seconds()
    };
    props.onCountDown(timeLeftObj);
    return timeLeftObj;
  };

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents: ReactNodeArray = [];

  Object.keys(timeLeft).forEach((interval, i) => {
    timerComponents.push(
      <div key={i}>
        <p className="timer-value">{timeLeft[interval]}</p>
        <p className="timer-header">{translate[props.prefix][interval]}</p>
      </div>
    );
  });

  return <div className="countdown-box">{timerComponents}</div>;
};

export default CountDown;

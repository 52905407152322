import moment from 'moment';
import queryString from 'query-string';

export const trackClickEvent = (obj: { [key: string]: any }) => {
  const datum = {
    event: 'track_event',
    event_action: 'click',
    ...obj
  };
  pushDataLayer(datum);
};

export const pushFromClickEvent = (event: any) => {
  const label = event.currentTarget.getAttribute('data-event-label');
  const category = event.currentTarget.getAttribute('data-event-category');

  trackClickEvent({
    event_label: label,
    event_category: category
  });
};

export const pushDataLayer = (obj: { [key: string]: any }) => {
  window.dataLayer.push(obj);
};

export const pushTrackEvent = (category: string, action: string, label: string) => {
  const datum = {
    event: 'track_event',
    event_action: action,
    event_label: label,
    event_category: category
  };

  pushDataLayer(datum);
};

export const dataLayer = (event?: any, eventAction = 'click', additional?: { [key: string]: any }) => {
  if (!event) {
    return;
  }
  if (!event.currentTarget) {
    return;
  }

  const e = event.currentTarget.getAttribute('data-event') || 'track_event';
  const label = event.currentTarget.getAttribute('data-event-label');
  const category = event.currentTarget.getAttribute('data-event-category');
  if (event.currentTarget.getAttribute('data-event-action')) {
    eventAction = event.currentTarget.getAttribute('data-event-action');
  }

  const datum = {
    event: e,
    event_action: eventAction,
    event_label: label,
    event_category: category,
    ...additional
  };

  pushDataLayer(datum);
};

export const defaultSearchDataLayerArgs = (filter: any) => {
  const query = queryString.parse(window.location.search);
  const dateFormat = 'YYYY-MM-DD HH:mm';

  const locationID = parseInt(filter.location.selected);
  const location = filter.location.list.find((l: any) => l.id === locationID);

  const carType =
    filter.category.selected
      .map((id: any) => {
        const selected = filter.category.list.find((c: any) => c.id === parseInt(id));
        if (!selected) {
          return '';
        }
        return selected.name.en;
      })
      .join(', ') || 'undefined';
  const dealer = query.dealers as string;
  const dealerName = dealer
    ? dealer
        .split(',')
        .map((id) => {
          const selected = filter.dealer.list.find((d: any) => d.id === parseInt(id));
          if (!selected) {
            return '';
          }
          return selected.name.en;
        })
        .join(', ')
    : 'undefined';

  return {
    event: 'track_event',
    search_pickup_date: moment(query.booking_begin, dateFormat).locale('en').format('YYYY-MMM-DD'),
    search_pickup_time: moment(query.booking_begin, dateFormat).locale('en').format('HH:mm:ss'),
    search_return_date: moment(query.booking_end, dateFormat).locale('en').format('YYYY-MMM-DD'),
    search_return_time: moment(query.booking_end, dateFormat).locale('en').format('HH:mm:ss'),
    search_destination: location.city.name.th,
    search_pickup_location: location.name.th,
    search_dealer_type: query.sources || 'undefined',
    search_dealer_name: dealerName,
    search_car_type: carType,
    search_promotion_name: 'undefined',
    search_rental_type: 'shortterm',
    sort_by: query.sort_by,
    search_time_stamp: moment().locale('en').format('YYYY-MMM-DD HH:mm:ss')
  };
};

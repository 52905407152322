import { dataLayer, pushDataLayer } from '@ui/utils/datalayer';
import React, { PureComponent } from 'react';
import moment from 'moment';
import { searchPath, appendParamsCurrentPage, setupDateTime } from '../../components/utils';
import { WEB_LEGACY_LINK, WEB_LEGACY_MANAGE_LINK, WEB_MANAGE_V3_WEB } from '../../config';

type FooterProps = {
  prefix: string;
};

class Footer extends PureComponent<FooterProps> {
  handleOnClickOnSocial(e: any) {
    dataLayer(e);
  }

  pushDataLayer = (type: string) => {
    const bookingBegin = setupDateTime(true, type);
    const bookingEnd = setupDateTime(false, type);

    let label = 'undefined';
    switch (type) {
      case 'undefined':
        label = 'เช่ารถระยะสั้น';
        break;
      case 'local':
        label = 'เช่ารถไม่ใช้บัตรเครดิต';
        break;
      case 'inter':
        label = 'เช่ารถบริษัทรายใหญ่';
        break;
    }
    const args = {
      search_pickup_date: bookingBegin.locale('en').format('YYYY-MMM-DD'),
      search_pickup_time: bookingBegin.locale('en').format('HH:mm:00'),
      search_return_date: bookingEnd.locale('en').format('YYYY-MMM-DD'),
      search_return_time: bookingEnd.locale('en').format('HH:mm:00'),
      search_dealer_type: type,
      event_label: `rental_type_${label}`,
      ...this.dataLayerArgs
    };

    pushDataLayer(args);
  };

  get dataLayerArgs() {
    return {
      event: 'track_event',
      event_category: 'footer_section',
      event_action: 'search_bookings_success',
      search_destination: 'เชียงใหม่', // example กรุงเทพมหานคร
      search_pickup_location: 'สนามบินเชียงใหม่', // example สนามบินดอนเมือง
      search_dealer_name: 'undefined', // example thai_rent_a_car, Hertz
      search_car_type: 'undefined', // example eco,suv,van
      search_promotion_name: 'undefined', // example onlybangkok
      search_rental_type: 'shortterm', // example shortterm,longterm,withdriver
      sort_by: 'price', // example price,raiting&price
      search_time_stamp: moment().locale('en').format('YYYY-MMM-DD HH:mm:ss')
    };
  }

  render() {
    const { prefix } = this.props;

    return (
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4 col-lg-3 company">
              <img src="/assets/shares/logo-white.svg" alt="Drivehub Logo" width="180" height="35" className="mb-2" />
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: 14,
                  marginBottom: '8px'
                }}
              >
                Drivehub Co.,Ltd
              </p>
              <p>
                <small>193-195 Lake Rajada Office Complex,Ratchadapisek road, Khlong Toei, Bangkok</small>
              </p>
              <p className="contact">
                <a
                  rel="noreferrer"
                  className="call mr-1"
                  data-event-category="footer_section"
                  data-event-action="contact_us"
                  data-event-label="callcenter"
                  onClick={(e) => dataLayer(e)}
                  href="tel:+6620385222"
                >
                  <i className="icon-phone" /> 02-038-5222
                </a>
                <a
                  rel="nofollow"
                  className="lineat"
                  data-event-category="footer_section"
                  data-event-action="contact_us"
                  data-event-label="line"
                  onClick={(e) => dataLayer(e)}
                  href="https://lin.ee/axOqULu"
                >
                  <img src="/assets/shares/line-icon.svg" alt="@drivehub" className="line-contact" /> @drivehub
                </a>
              </p>
              <a
                rel="nofollow noopener noreferrer"
                data-event-category="footer_section"
                data-event-action="click"
                data-event-label="dbd_verified"
                onClick={(e) => dataLayer(e)}
                href="https://www.trustmarkthai.com/callbackData/popup.php?data=e1-26-5-7561fa57af7580f8eaf21cba866846d785a25f4705e"
              >
                <img
                  src="/assets/shares/bns_registered.jpg"
                  alt="DBD Registered"
                  height="40"
                  width="170"
                  className="mb-2"
                />
                {/* <picture>
                  <source srcSet="/assets/shares/bns_registered.webp" type="image/webp" />
                  <source srcSet="/assets/shares/bns_registered.jpg" type="image/jpeg" />
                  <img
                    src="/assets/shares/bns_registered.jpg"
                    alt="DBD Registered"
                    height="40"
                    width="170"
                    className="mb-2"
                  />
                </picture> */}
              </a>
            </div>
            {/* end col */}
            <div className="col-12 col-md-8 col-lg-8 ml-auto menu">
              <div className="row">
                <div className="col-6 col-sm-3">
                  <h5>ติดตามเรา</h5>
                  <ul className="list-inline social-link">
                    <li className="list-inline-item">
                      <a
                        data-event-category="footer_section"
                        data-event-action="subscribe"
                        data-event-label="facebook"
                        onClick={(e) => this.handleOnClickOnSocial(e)}
                        href="https://fb.com/drivehub.th"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        <i className="icon-facebook-official"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        data-event-category="footer_section"
                        data-event-action="subscribe"
                        data-event-label="twitter"
                        onClick={(e) => this.handleOnClickOnSocial(e)}
                        href="https://twitter.com/drivehubth"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        <i className="icon-twitter-squared"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        data-event-category="footer_section"
                        data-event-action="subscribe"
                        data-event-label="instagram"
                        onClick={(e) => this.handleOnClickOnSocial(e)}
                        href="https://instagram.com/drivehub.th"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        <i className="icon-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-6 col-sm-3">
                  <h5>เกี่ยวกับ Drivehub</h5>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        data-event-category="footer_section"
                        data-event-action="click_aboutus"
                        data-event-label="บริการของเรา"
                        onClick={(e) => dataLayer(e)}
                        href={`${WEB_LEGACY_LINK}/${prefix}/services`}
                      >
                        บริการของเรา
                      </a>
                    </li>
                    <li>
                      <a
                        data-event-category="footer_section"
                        data-event-action="click_aboutus"
                        data-event-label="ประเภทรถเช่า"
                        onClick={(e) => dataLayer(e)}
                        href={`${WEB_LEGACY_LINK}/${prefix}/rental-type`}
                      >
                        ประเภทรถเช่า
                      </a>
                    </li>
                    <li>
                      <a
                        data-event-category="footer_section"
                        data-event-action="click_aboutus"
                        data-event-label="บล็อก"
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => dataLayer(e)}
                        href="https://www.drivehub.co/blog"
                      >
                        บล็อก
                      </a>
                    </li>
                    <li>
                      <a
                        data-event-category="footer_section"
                        data-event-action="click_aboutus"
                        data-event-label="ติดต่อ"
                        onClick={(e) => dataLayer(e)}
                        href={`${WEB_LEGACY_LINK}/${prefix}/contact`}
                      >
                        ติดต่อ
                      </a>
                    </li>
                    <li>
                      <a
                        data-event-category="footer_section"
                        data-event-action="click_aboutus"
                        data-event-label="ร่วมเป็นพันธมิตรกับเรา"
                        onClick={(e) => dataLayer(e)}
                        href={`${WEB_LEGACY_LINK}/${prefix}/list-your-car`}
                      >
                        ร่วมเป็นพันธมิตรกับเรา
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-6 col-sm-3">
                  <h5>บริการของ Drivehub</h5>
                  <ul className="list-unstyled">
                    <li>
                      <a href={searchPath('rent_short')} onClick={() => this.pushDataLayer('undefined')}>
                        เช่ารถระยะสั้น
                      </a>
                    </li>
                    <li>
                      <a
                        data-event-category="footer_section"
                        data-event-action="click"
                        data-event-label="rental_type_เช่ารถระยะยาว"
                        onClick={(e) => dataLayer(e)}
                        href={`${WEB_LEGACY_LINK}/${prefix}/long-term-rental`}
                      >
                        เช่ารถระยะยาว
                      </a>
                    </li>
                    <li>
                      <a
                        data-event-category="footer_section"
                        data-event-action="click"
                        data-event-label="rental_type_เช่ารถพร้อมคนขับ"
                        onClick={(e) => dataLayer(e)}
                        href={`${WEB_LEGACY_LINK}/${prefix}/car-with-driver`}
                      >
                        เช่ารถพร้อมคนขับ
                      </a>
                    </li>
                    <li>
                      <a href={searchPath('rent_local')} onClick={() => this.pushDataLayer('local')}>
                        เช่ารถไม่ใช้บัตรเครดิต
                      </a>
                    </li>
                    <li>
                      <a href={searchPath('rent_inter')} onClick={() => this.pushDataLayer('inter')}>
                        เช่ารถบริษัทรายใหญ่
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-6 col-sm-3">
                  <h5>ความช่วยเหลือ</h5>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        data-event-category="footer_section"
                        data-event-action="click_help"
                        data-event-label="วิธีการจอง"
                        onClick={(e) => dataLayer(e)}
                        href={`${WEB_LEGACY_LINK}/${prefix}/how-to-book`}
                      >
                        วิธีการจอง
                      </a>
                    </li>
                    <li>
                      <a
                        data-event-category="footer_section"
                        data-event-action="click_help"
                        data-event-label="เอกสารเช่ารถ"
                        onClick={(e) => dataLayer(e)}
                        href={`${WEB_LEGACY_LINK}/${prefix}/documents`}
                      >
                        เอกสารเช่ารถ
                      </a>
                    </li>
                    <li>
                      <a
                        data-event-category="footer_section"
                        data-event-action="click_help"
                        data-event-label="ประกันภัย"
                        onClick={(e) => dataLayer(e)}
                        href={appendParamsCurrentPage(`${WEB_LEGACY_LINK}/${prefix}/insurance`)}
                      >
                        ประกันภัย
                      </a>
                    </li>
                    <li>
                      <a
                        data-event-category="footer_section"
                        data-event-action="click_help"
                        data-event-label="ความช่วยเหลือเพิ่มเติม"
                        onClick={(e) => dataLayer(e)}
                        href={appendParamsCurrentPage(`${WEB_LEGACY_LINK}/${prefix}/help-center`)}
                      >
                        ความช่วยเหลือเพิ่มเติม
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <ul className="list-inline d-inline">
              <li className="list-inline-item">
                <small>© Drivehub 2020</small>
              </li>
              <li className="list-inline-item">
                <a
                  data-event-category="footer_section"
                  data-event-action="click"
                  data-event-label="terms"
                  onClick={(e) => dataLayer(e)}
                  href={`${WEB_LEGACY_LINK}/${prefix}/terms`}
                >
                  <small>ข้อตกลงและเงื่อนไข</small>
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  data-event-category="footer_section"
                  data-event-action="click"
                  data-event-label="privcay"
                  onClick={(e) => dataLayer(e)}
                  href={`${WEB_LEGACY_LINK}/${prefix}/privacy`}
                >
                  <small>นโยบายความเป็นส่วนตัว</small>
                </a>
              </li>
              <li className="list-inline-item dealer-login">
                <a
                  data-event-category="footer_section"
                  data-event-action="click"
                  data-event-label="dealer_login"
                  onClick={(e) => dataLayer(e)}
                  href={`${WEB_LEGACY_MANAGE_LINK}/th/employees/sign_in`}
                >
                  <small>ผู้ประกอบกาารเข้าสู่ระบบ</small>
                </a>
              </li>
              <li className="list-inline-item dealer-login">
                <a
                  data-event-category="footer_section"
                  data-event-action="click"
                  data-event-label="dealer_login_v3"
                  onClick={(e) => dataLayer(e)}
                  href={`${WEB_MANAGE_V3_WEB}/staff/login`}
                >
                  <small>ผู้ประกอบกาารเข้าสู่ระบบ (V3)</small>
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* enf container */}
      </footer>
    );
  }
}

export default Footer;

import { sendToDataLayer } from '@utils';

export const PROMOTION_DETAIL_EVENT_CATEGORY = 'promotion_article_section';

export const trackPrmotionDetailBackBtn = () => {
  sendToDataLayer(undefined, {
    event_category: PROMOTION_DETAIL_EVENT_CATEGORY,
    event_action: 'back',
    event_label: 'all_promotion_page'
  });
};
